import TableAction from "components/TableAction";
import { numberWithCommas } from "utils/calculators";

import isoToDate from "utils/isoToDate";
import { Link } from "react-router-dom";
import routes from "operations/routing/routes";
import OrderStatusButton from "./OrderStatusButton";
import { PAYMENT_DATA_OBJECT } from "enumerations";
import TranslateByObject from "../../../components/TranslateByObject";
import { Badge } from "react-bootstrap";
import DeliveryTooltip from "./DeliveryTooltip";

export const tableData = ({ onClick, newRoute, isNetworkDry, ...props }) =>
  // columns: ["Name", "Network type", "Default", "Actions"],
  // body: data?.map((param) => {
  //     return [
  //         param.name,
  //         param.network_type,
  //         (param.is_default)?.toString(),
  //         () => {
  //             return (
  //                 <div className="d-flex">
  //                     <Link to={routes[ROUTE_NAME].edit + '/' + param.id} className="btn btn-primary shadow btn-xs sharp mr-1"  >
  //                         <i className="fa fa-pencil"></i>
  //                     </Link>
  //                     <span className="btn btn-danger shadow btn-xs sharp pointer" onClick={() => onClick({ param, type: 'delete' })} >
  //                         <i className="fa fa-trash pointer"></i>
  //                     </span>
  //                 </div>
  //             )
  //         }
  //     ]
  // }),
  [
    //
    {
      header: "actions",
      Component: ({ param }) => (
        <TableAction {...{ param, newRoute, onClick, ...props }} />
      ),
    },
    {
      header: "status",
      Component: ({ param }) => {
        // if(param.vigoDelivery)alert("")
        // console.log({ param, ORDER_STATUS_OBJECT });
        return param.status ? (
          <OrderStatusButton
            {...{
              param,
              onClick,
              isBranch: props.isBranch,
              isHotel: props.isHotel,
              isAdmin: props.isAdmin,
              ...props,
            }}
          />
        ) : (
          "-"
        );
      },
    },
    ...(!props.isBranch
      ? [
          {
            header: "delivery",
            Component: ({ param }) => (
              <DeliveryTooltip
                disabled={props.isBranch}
                {...{ isHotel: props.isHotel, isAdmin: props.isAdmin }}
                vigoDelivery={param?.vigoDelivery}
                delivery={param.delivery}
              >
                <div className="d-flex align-items-center">
                  {props.isAdmin && param.delivery?.telegramId ? (
                    <Badge
                      onClick={() => onClick({ data: param, key: "unassign" })}
                      bg="info"
                      className="mr-2 cursor-pointer"
                    >
                      <i className="mdi mdi-close" />
                    </Badge>
                  ) : (
                    ""
                  )}
                  <div className="flex flex-column">
                    <p className="mb-0">
                      {param.delivery?.telegramName ||
                        param.delivery?.telegramUsername ||
                        (param?.vigoDelivery
                          ? param?.vigoDelivery?.assignedCourier?.fullname
                          : // "Delivery"
                            "Atanmadı")}
                    </p>
                    <p className="mb-0">
                      {param?.vigoDelivery
                        ? param?.vigoDelivery?.assignedCourier?.cellPhone
                        : ""}
                    </p>
                  </div>
                </div>
              </DeliveryTooltip>
            ),
            // Component: ({ param }) =>
            //   param.delivery?.telegramName ||
            //   param.delivery?.telegramUsername ||
            //   "Atanmadı",
          },
        ]
      : []),

    {
      header: "orderNumber",
      Component: ({ param }) => param?.orderNumber || "-",
    },
    {
      header: "vigo",
      Component: ({ param }) => param?.orderNumber || "-",
    },
    // {
    //   header: "margin",
    //   Component: ({ param }) => ((param.totalPriceWithoutFee||0) -( param.totalOfBuyPrices||0) - 1505 - ( 1 - (hotel.commission||0))) || "-",
    // },

    ...(!props.isBranch
      ? [
          {
            header: "nameSurname",
            Component: ({ param }) => param.name || "name",
          },
        ]
      : []),
    ...(props.isBranch && isNetworkDry
      ? [
          {
            header: "roomNumber",
            Component: ({ param }) => param.roomNumber || "-",
          },
          {
            header: "hotelName",
            Component: ({ param }) => param.hotel?.name || "-",
          },
          {
            header: "address",
            Component: ({ param }) => param.hotel?.address || "-",
          },
          {
            header: "phoneNumber",
            Component: ({ param }) => param.hotel?.phoneNumber || "-",
          },
        ]
      : []),
    ...(!props.isBranch
      ? [
          {
            header: "hotel",
            Component: ({ param }) => {
              const { base, edit } = routes.hotel;
              return param.hotel ? (
                <Link to={`${base + edit}/${param.hotel.id}`} target="_blank">
                  {" "}
                  {param.hotel?.name}{" "}
                </Link>
              ) : (
                "-"
              );
            },
          },
        ]
      : []),

    ...(!props.isBranch
      ? [
          {
            header: "roomNumber",
            Component: ({ param }) => param.roomNumber || "-",
          },

          {
            header: "totalPriceEuro",
            Component: ({ param }) => numberWithCommas(param.totalPrice) || "-",
          },
          {
            header: "totalPriceTry",
            Component: ({ param }) =>
              numberWithCommas(param.totalPriceTry) || "-",
          },
          {
            header: "paymentCurrency",
            Component: ({ param }) =>
              param.paymentCurrency === "try"
                ? "Türk Lirası"
                : param.paymentCurrency === "usd"
                ? "Dolar"
                : param.paymentCurrency === "eur"
                ? "Euro"
                : "-",
          },
          {
            header: "euro",
            Component: ({ param }) =>
              numberWithCommas(param.euro.toFixed(2)) || "-",
          },

          {
            header: "dollar",
            Component: ({ param }) =>
              numberWithCommas(param.dollar.toFixed(2)) || "-",
          },

          {
            header: "paymentType",
            Component: ({ param }) =>
              (
                <TranslateByObject
                  {...(PAYMENT_DATA_OBJECT[param.paymentType] &&
                    PAYMENT_DATA_OBJECT[param.paymentType])}
                />
              ) || "-",
          },

          {
            header: "region",
            Component: ({ param }) => {
              const { base, edit } = routes.region;
              // const currRegion = props?.isAdmin ? param?.region : param?.hotel?.region
              const currRegion = param?.hotel?.region;
              return currRegion ? (
                <Link to={`${base + edit}/${currRegion?.id}`} target="_blank">
                  {" "}
                  {currRegion?.name}{" "}
                </Link>
              ) : (
                "-"
              );
            },
          },
        ]
      : []),
    {
      header: "note",
      Component: ({ param }) => param.note || "-",
    },

    {
      header: "createdAt",
      Component: ({ param }) => isoToDate(param.createdAt),
    },
    // {
    //   header: "Status",
    //   Component: ({ param }) => <TableStatus {...{ param, onClick }} />,
    // },
  ];
